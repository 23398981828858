import React from 'react';

const QuoteIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="72"
    viewBox="0 0 100 72"
  >
    <path
      fill="#F7DADA"
      d="M20.571 71.429c11.572 0 20.143-9 20.143-21.429 0-7.714-3-14.571-9.428-18.429L45.429.286H26.57L6.43 31.143C2.57 37.143 0 42.714 0 49.57 0 63.286 9.429 71.43 20.571 71.43zm54 0c11.572 0 20.143-9 20.143-21.429 0-7.714-3-14.571-9.428-18.429L99.429.286H80.57L60.43 31.143C56.57 37.143 54 42.714 54 49.57c0 13.715 9.429 21.858 20.571 21.858z"
    />
  </svg>
);

export default QuoteIcon;
