import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import Hero from 'components/Hero';
import QuoteIcon from 'components/Icons/QuoteIcon';
import FacebookShareIcon from 'components/Icons/FacebookShareIcon';
import TwitterShareIcon from 'components/Icons/TwitterShareIcon';
import LinkedinShareIcon from 'components/Icons/LinkedinShareIcon';
import useSiteMetadata from 'hooks/useSiteMetadata';
import SharePopup from 'utils/share';
import { resolveSlice } from 'utils/slice-resolver.js';

import css from './blog-post.module.scss';
import shared from 'styles/shared.module.scss';

const BlogPost = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const content = prismic.allBlog_posts.edges[0];
  if (!content) return null;

  const post = content.node;
  const image = post.imageSharp;
  const contentGroup = post.content || [];
  const socialImage = post.meta_image ? post.meta_image.url : post.image.url;
  const slices = post.body || [];
  const heroButton = post.hero_button_anchor
    ? {
        anchor: post.hero_button_anchor,
        label: post.hero_button_label,
      }
    : null;

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - ${RichText.asText(post.title)}`}</title>
        <meta description={RichText.asText(post.abstract)} />
        <meta property="og:title" content={RichText.asText(post.title)} />
        <meta
          property="og:description"
          content={RichText.asText(post.abstract)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={socialImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={RichText.asText(post.title)} />
        <meta
          property="twitter:description"
          content={RichText.asText(post.abstract)}
        />
        <meta name="twitter:creator" content="@swissdecode" />
        <meta name="twitter:image" content={socialImage} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        title={post.title}
        description={post.abstract}
        button={heroButton}
      />
      <article className={`${css.content} ${shared.maxwidth}`}>
        {contentGroup.map((content, idx) => (
          <React.Fragment key={idx}>
            {content.quote ? (
              <div className={classNames(css.contentGroup, css.quote)}>
                <QuoteIcon />
                <span>{content.quote}</span>
                <QuoteIcon />
              </div>
            ) : null}
            <div
              className={classNames(css.contentGroup, {
                [css.leftAlign]: content.image_alignment === 'left',
                [css.centerAlign]: content.image_alignment === 'center',
                [css.rightAlign]: content.image_alignment === 'right',
              })}
            >
              <RichText render={content.text} />
            </div>
          </React.Fragment>
        ))}
        <div className={css.footer}>
          <div>
            <div className={css.author}>{post.author}</div>
            <div className={css.date}>{post.date}</div>
          </div>
          <div className={css.footerShare}>
            <button
              type="button"
              className={css.facebookShare}
              onClick={() => SharePopup.facebook()}
            >
              <FacebookShareIcon width={16} height={16} />
            </button>
            <button
              type="button"
              className={css.twitterShare}
              onClick={() => SharePopup.twitter()}
            >
              <TwitterShareIcon width={20} height={16} />
            </button>
            <button
              type="button"
              className={css.linkedinShare}
              onClick={() => SharePopup.linkedin()}
            >
              <LinkedinShareIcon width={16} height={16} />
            </button>
          </div>
        </div>
      </article>
      {slices.map(resolveSlice)}
    </>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query PostById($uid: String) {
    prismic {
      allBlog_posts(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            image
            imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            abstract
            content {
              quote
              text
              image_alignment
            }
            author
            date
            meta_image
            hero_button_label
            hero_button_anchor
            body {
              ... on PRISMIC_Blog_postBodyForm_section {
                type
                primary {
                  form_section_id
                  form_id
                  form_label
                  form_title
                  form_id
                  form_privacy
                  form_success
                  linkedin_conversion
                }
              }
            }
          }
        }
      }
    }
  }
`;
